<template>
  <Layout>
    <Header />
    <div class="container-fluid p-0">
      <div class="banner position-relative">
        <img src="@/assets/images/app/get-care.jpg" :alt="title" class="img-fluid">
        <div class="position-absolute top-50 start-0 translate-middle-y w-100">
          <div class="container text-white px-3">
              <div class="row justify-content-end">
              <div class="col-lg-8 col-10 text-end">
                <h1 class="text-white display-4 animate__animated animate__slideInRight font-style-2 mt-4 mt-md-0">{{$t('get_care')}}  </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row">
        <div class="col-12">
          <h2 class="display-6 text-primary font-style-2 text-center fw-semibold">{{$t('your_care_is_our_priority2')}}</h2>
          <p class="text-center">{{$t('thankyouchoosing')}}</p>
          <div class="p-3 enquiry">
          <div class="form-group mb-3">
            <select class="form-select form-control-lg bg-light border-light" v-model="formData.enquiry_type">
              <option value="1">{{$t('general_enquiry')}}</option>
              <option value="2">{{$t('appointment')}}</option>
            </select>
          </div>
          <div class="form-group mb-3">
            <select class="form-select form-control-lg bg-light border-light" v-model="formData.patient">
              <option value="1">{{$t('new_patient')}}</option>
              <option value="2">{{$t('existing_patient')}}</option>
            </select>
          </div>
          <div class="form-group mb-3">
            <input type="text" class="form-control bg-light border-light" id="name" :placeholder="$t('name')"
            v-model.trim="formData.name"
            :class="{
              'is-invalid': submitted && $v.formData.name.$error,
            }"
            >
            <div
                v-if="submitted && $v.formData.name.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.formData.name.required"
                  >{{$t('name')}}{{$t('is_required')}}</span
                >
            </div>
          </div>
          <div class="form-group mb-3">
            <input type="tel" class="form-control bg-light border-light" id="contact" :placeholder="$t('contact')"  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
            v-model.trim="formData.phone"
            :class="{
              'is-invalid': submitted && $v.formData.phone.$error,
            }"
            >
              <div
                v-if="submitted && $v.formData.phone.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.formData.phone.required"
                  >{{$t('phone')}}{{$t('is_required')}}</span
                >
                <span v-if="!$v.formData.phone.countryCodeAndPhone">
                  {{$t('phone_invalid')}}
                </span>
              
              </div>
          </div>
          <div class="form-group mb-3">
            <input type="email" class="form-control bg-light border-light" id="email" :placeholder="$t('email')"
            v-model.trim="formData.email"
            :class="{
              'is-invalid': submitted && $v.formData.email.$error,
            }"
            >
            <div
                v-if="submitted && $v.formData.email.$error"
                class="invalid-feedback"
              >
              <span v-if="!$v.formData.email.required"
                >{{$t('email')}}{{$t('is_required')}}</span
              >
              <span v-if="!$v.formData.email.email">
                {{$t('email_invalid')}}
              </span>
            </div>
          </div>
          <div class="form-group mb-3" >
            <div class="row">
          <div class="col-6">
            <date-picker
              readonly
              v-model="formData.appointmentDate"
              lang="en"
              value-type="format"
              format="YYYY-MM-DD"
              :disabled-date="disabledBeforeTodayAndAfterAWeek"
              :placeholder="$t('appointment_date')"
            ></date-picker>
           
          </div>
          <div class="col-6">
            <date-picker
              v-model="formData.appointmentTime"
              lang="en"
              type="time"
              readonly
              value-type="format"
              format="hh:mm A"
              :minute-step="10"
              :disabled-time="isTimeDisabled"
              :placeholder="$t('appointment_time')"
            ></date-picker>
          </div>
        </div>
            
           
          </div>
          <div class="form-group mb-3">
            <textarea class="form-control bg-light border-light" id="message" rows="4"
            v-model.trim="formData.enquiry"
            :placeholder="$t('message')"
            :class="{
              'is-invalid': submitted && $v.formData.enquiry.$error,
            }"
            ></textarea>
            <div
                v-if="submitted && $v.formData.enquiry.$error"
                class="invalid-feedback"
              >
              <span v-if="!$v.formData.enquiry.required"
                >{{$t('message')}} {{$t('is_required')}}</span
              >
            </div>
          </div>
          <div class="form-group input-group mb-3">
            <span class="input-group-text border-light" id="basic-addon1">{{mathQuestion}}</span>
            <input type="tel" class="form-control bg-light border-light" id="quest" :placeholder="$t('answer')"
            v-model.trim="formData.mathAnswer" oninput="this.value = this.value.replace(/[^+-\d]/g, '').replace(/(\+?[\d-]{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
            :class="{
              'is-invalid': submitted && $v.formData.mathAnswer.$error,
            }"
            >
            <div
                v-if="submitted && $v.formData.mathAnswer.$error"
                class="invalid-feedback"
              >
              <span v-if="!$v.formData.mathAnswer.required"
                >{{$t('anwser_required')}}</span
              >
              <span v-else-if="!$v.formData.mathAnswer.isCorrect"
                >{{$t('wrong_anwser')}}</span
              >
            </div>
          </div>
          <div class="mb-0 text-end">
            <button class="btn btn-primary w-100 fw-medium" :disabled="loading" @click="submit" type="button">
            <span v-if="loading">{{$t('submitting')}}</span>
            <span v-else> {{$t('Submit')}}</span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="loading" role="status"
              aria-hidden="true"></span>
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
    <Contact />
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import Header from "@/views/pages/drlim/components/header";
import Contact from '@/views/pages/drlim/components/contact';
import { required, minLength, email, helpers} from "vuelidate/lib/validators";
export const countryCodeAndPhone = (value) => {
  const regex = /^\d{8,15}?$/
  return helpers.regex('countryCodeAndPhone', regex)(value)
}
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Starter page
 */
export default {
  components: { Layout , Header, Contact, DatePicker},
  page: {
    title: "Get Care",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  
  data() {
    return {
      title: appConfig.title,
      items: [],
      submitted:false,
      loading:false,
      mathQuestion: null,
      answer:null,
      formData:{
        enquiry_type:"1",
        patient:"1",
        appointmentDate: "",
        appointmentTime:"09:00 AM",
        name:"",
        email:"",
        phone:"",
        enquiry:"",
        mathAnswer:null
      },
    };
  },
  validations: {
    formData:{
      name: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        countryCodeAndPhone,
        minLength: minLength(10)
      },
      enquiry: {
        required,
      },
      mathAnswer: {
      required,
      isCorrect(value) {
        return Number(value) === this.answer;
      }
    }
    }
  },
  created(){
    this.generateMathQuestion();
    this.formData.appointmentDate = this.formatDate();
  },
  mounted(){
  },
  methods: {
    formatDate(){
      const appointmentDate = new Date();
      const year = appointmentDate.getFullYear();
      const month = ('0' + (appointmentDate.getMonth() + 1)).slice(-2);
      const day = ('0' + appointmentDate.getDate()).slice(-2);
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    disabledBeforeTodayAndAfterAWeek(date){
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const isSunday = date.getDay() === 0; // Sunday is represented by 0

      return date < today || isSunday;
    },
    isTimeDisabled(date) {
        const hour = date.getHours();
        return !(hour > 8 && hour < 22);
    },
    generateMathQuestion() {
      const num1 = Math.floor(Math.random() * 10) + 1;
      const num2 = Math.floor(Math.random() * 10) + 1;
      const operators = ['+', '-', '*'];
      const operator = operators[Math.floor(Math.random() * operators.length)];
      const question = `${num1} ${operator} ${num2} = ?`;
      let answer;
      switch (operator) {
        case '+':
          answer = num1 + num2;
          break;
        case '-':
          answer = num1 - num2;
          break;
        case '*':
          answer = num1 * num2;
          break;
        case '/':
          answer = num1 / num2;
          break;
      }
      this.mathQuestion = question;
      this.answer = answer;
    },
    submit(){
      this.submitted = true
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.$Progress.start();
          this.loading = true
          var bodyFormData = new FormData();
        
          bodyFormData.append("enquiryType",  this.formData.enquiry_type == 1 ? 'General Enquiry' : 'Appointment');
          bodyFormData.append("patient",  this.formData.patient == 1 ? 'New Patient' : 'Existing Patient');
          bodyFormData.append("name",this.formData.name);
          bodyFormData.append("email",this.formData.email);
          bodyFormData.append("phone",this.formData.phone);
          bodyFormData.append("appointmentDate",this.formData.appointmentDate);
          bodyFormData.append("appointmentTime",this.formData.appointmentTime);
          bodyFormData.append("enquiry",this.formData.enquiry);
          axios({
              method: "post",
              url: "https://controller.limsurgery.com/controller/mail/submitCareEnquiry",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.formData={
                  enquiry_type:"1",
                  patient:"1",
                  appointmentDate:"",
                  appointmentTime:"",
                  name:"",
                  email:"",
                  phone:"",
                  enquiry:"",
                  mathAnswer:null
                },
                Swal.fire(
                  {
                  icon: 'success',
                  title: this.$t('success'),
                  html:  this.$t('successMsg'),
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('close'),
                })
                this.formData.appointmentDate = this.formatDate();
                this.formData.appointmentTime = "10:00 AM"
              } else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: this.$t('Oops'),
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('close'),
                  })
                  this.$Progress.fail();
              }
              this.loading = false;
              this.submitted = false;
              this.$Progress.finish()
              this.generateMathQuestion();
          })
          .catch((error)=> {
              this.loading = false;
              this.submitted = false;
              this.$Progress.finish();
              this.generateMathQuestion();
              Swal.fire(
                {
                icon: 'error',
                title: this.$t('Oops'),
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('done'),
              })
        });
      }
    }
  },
}
</script>
<style>
.enquiry input, .enquiry select{
  padding: 12px 15px;
}
.enquiry button{
  padding: 10px 15px;
  font-size: 15px;
}
.enquiry .mx-input-wrapper .mx-input{
  border-radius: 5px !important;
  padding: 12px 15px !important;
  background-color:#f5f6f8 !important;
  border-color:#f5f6f8 !important;
  height: auto !important;
}
.enquiry .mx-datepicker.is-invalid .mx-input{
  border-color: #DA0011 !important;
}
.enquiry .is-invalid{
  border-color: #DA0011 !important;
}
</style>